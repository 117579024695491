import React from "react"
import PageTemplate from "../components/pageTemplate"
import { Container, Row, Col } from "reactstrap"
let Service = ({ title, years, unit }) => (
  <Col xs={12} md={3} className="mb-3">
    <div class="fk-editor">
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae" style="font-size: 16px;">超过</font>
      </div>
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae"><b>{years}</b><span style="font-size: 16px;">{unit}</span></font>
      </div>
      <div style="text-align: center; font-size: 34px; line-height: 30px;">
        <font color="#1e50ae" style="font-size: 16px;">{title}</font></div>
    </div>
  </Col>
)
let About = () => (
    <PageTemplate title="公司概况">
      <p>
        天津市长庆电子科技有限公司成立于1995年，主要从事智能电子产品的研究开发工作。集计算机软、硬件及智能电子产品开发、生产、销售及售后服务于一体，拥有多项国家专利。公司坚持服务社会，共同发展的理念，秉承“诚信、务实、敬业、创新”的企业精神，为客户提供了安全、高效、优质的售后服务。
      </p>
      <Container className="py-5">
      <Row>
        <Service title="专项经营" years="24" unit="年" />
        <Service title="国家专利" years="13" unit="项" />
        <Service title="专利产品" years="30" unit="款" />
        <Service title="优质客户" years="1000" unit="家" />
      </Row>
    </Container>
    </PageTemplate>
)

export default About
